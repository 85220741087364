import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import { getFunctions, httpsCallable } from 'firebase/functions'

const AdminAddPopup = ({ open, handleClose }) => {
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('')
  const [role, setRole] = useState('')
  const [password, setPassword] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)

  const handleEmailChange = (event) => {
    const value = event.target.value
    const isValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
    setEmail(value)
    setEmailError(!isValid)
  }

  const handlePasswordChange = (event) => {
    const value = event.target.value
    const isValid = value.length >= 8
    setPassword(value)
    setPasswordError(!isValid)
  }

  //PROFILE COLOR
  const getRandomColor = () => {
    const colors = ['#A3EC81', '#8AD6F5', '#E95031', '#F59C46']
    return colors[Math.floor(Math.random() * colors.length)]
  }

  //ADD ADMIN TO FIREBASE
  const functions = getFunctions()
  const addNewAdmin = httpsCallable(functions, 'addNewAdmin')
  const handleSignUp = async (e) => {
    e.preventDefault()

    try {
      const result = await addNewAdmin({
        email: email,
        password: password,
        username: username,
        role: role,
        profileImageColor: getRandomColor(),
      })
      resetForm()
    } catch (error) {
      console.log('Registration failed: ' + error.message)
    }
  }

  //FORM HANDLE
  const resetForm = () => {
    setEmail('')
    setUsername('')
    setRole('')
    setPassword('')
    setEmailError(false)
    setPasswordError(false)
    handleClose()
  }

  const isSaveDisabled = !email || !username || !role || !password || emailError || passwordError

  return (
    <Dialog open={open} onClose={resetForm} maxWidth="sm" fullWidth>
      <DialogTitle style={{ fontFamily: 'Inter Bold', color: '#f59c46' }}>
        Betreuer Hinzufügen
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            label="E-Mail"
            value={email}
            onChange={handleEmailChange}
            error={emailError}
            helperText={emailError ? 'Keine gültige E-Mail' : ''}
            required
            fullWidth
            margin="normal"
            autoComplete="email"
          />
          <TextField
            label="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            fullWidth
            multiline
            margin="normal"
          />
          <FormControl fullWidth required margin="normal">
            <InputLabel id="role-label">Rolle</InputLabel>
            <Select
              labelId="role-label"
              value={role}
              onChange={(e) => setRole(e.target.value)}
              label="Rolle"
            >
              <MenuItem value="Betreuer">Betreuer</MenuItem>
              <MenuItem value="Moderator">Moderator</MenuItem>
              <MenuItem value="Admin">Admin</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Passwort"
            value={password}
            onChange={handlePasswordChange}
            error={passwordError}
            helperText={emailError ? 'Mindestens 8 Zeichen lang' : ''}
            required
            fullWidth
            multiline
            margin="normal"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetForm} variant="secondary">
          Abbrechen
        </Button>
        <Button
          disabled={isSaveDisabled}
          variant="primary"
          onClick={handleSignUp}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AdminAddPopup
