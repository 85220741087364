import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@mui/material'
import { ref, set } from 'firebase/database'
import { database } from '../../../firebase'

const KarteAdd = ({ open, handleClose }) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [website, setWebsite] = useState('')
  const [phone, setPhone] = useState('')
  const [street, setStreet] = useState('')
  const [plz, setPlz] = useState('')
  const [city, setCity] = useState('')
  const [plzError, setPlzError] = useState(false)
  const [websiteError, setWebsiteError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)

  const geocodeApiKey = process.env.REACT_APP_GEOCODE_API_KEY

  const saveToFirebase = async () => {
    try {
      const newStreet = encodeURIComponent(street)
      await fetch(
        `https://geocode.maps.co/search?q=${newStreet}+${plz}+${city}&api_key=${geocodeApiKey}`
      )
        .then((response) => {
          if (!response.ok) {
            throw new Error(
              'Network response was not ok ' + response.statusText
            )
          }
          return response.json()
        })
        .then((json) => {
          const responseLat = Number(json[0].lat)
          const responseLon = Number(json[0].lon)
          if (!isNaN(responseLat) && !isNaN(responseLon)) {
            if (responseLat != 0 && responseLon != 0) {
              const data = {
                address: `${street}, ${plz} ${city}`,
                description: description,
                tel: `${phone}`,
                title: name,
                url: website,
                coordinates: {
                  latitude: responseLat,
                  longitude: responseLon,
                },
              }

              const newContactRef = ref(database, 'contactPoints/' + Date.now())
              set(newContactRef, data)
              resetForm()
            } else {
              console.log(`Error: ${responseLat} or ${responseLon} == 0`)
            }
          } else {
            console.error('Latitude or Longitude is not a number.')
          }
        })
        .catch((error) => {
          console.log('ungültige Adresse: ', error)
          alert('Ungültige Adresse')
        })
    } catch (error) {
      console.error('Fehler beim Speichern der Daten: ', error)
    }
  }

  const handlePlzChange = (event) => {
    const value = event.target.value
    const isValid = /^[0-9]{4}$/.test(value)
    setPlz(value)
    setPlzError(!isValid)
  }

  const handleWebsiteChange = (event) => {
    const value = event.target.value
    const isValid =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(
        value
      )
    setWebsite(value)
    setWebsiteError(!isValid)
  }

  const handlePhoneChange = (event) => {
    const value = event.target.value
    const isValid = /^(\+43|\+49)[0-9]{10,15}$/.test(value)
    setPhone(value)
    setPhoneError(!isValid)
  }

  const resetForm = () => {
    setName('')
    setDescription('')
    setWebsite('')
    setPhone('')
    setStreet('')
    setPlz('')
    setCity('')
    setPlzError(false)
    setWebsiteError(false)
    setPhoneError(false)
    handleClose()
  }

  const isSaveDisabled =
    !name ||
    !description ||
    !website ||
    !phone ||
    !street ||
    !plz ||
    !city ||
    plzError ||
    websiteError ||
    phoneError

  return (
    <Dialog open={open} onClose={resetForm} maxWidth="sm" fullWidth>
      <DialogTitle style={{ fontFamily: 'Inter Bold', color: '#f59c46' }}>
        Standort Hinzufügen
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Beschreibung"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Website"
                value={website}
                onChange={handleWebsiteChange}
                error={websiteError}
                helperText={websiteError ? 'Keine gültige URL' : ''}
                required
                fullWidth
                margin="normal"
                placeholder="https://example.com"
                type="url"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Telefon"
                value={phone}
                onChange={handlePhoneChange}
                error={phoneError}
                helperText={phoneError ? 'Keine gültige Telefonnummer' : ''}
                required
                fullWidth
                margin="normal"
                placeholder="+4369912345678"
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={7}>
              <TextField
                label="Straße"
                value={street}
                onChange={(e) => setStreet(e.target.value)}
                required
                fullWidth
                margin="normal"
                placeholder="Lessingstraße 6"
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                label="PLZ"
                value={plz}
                onChange={handlePlzChange}
                error={plzError}
                helperText={plzError ? 'Keine gültige PLZ' : ''}
                required
                fullWidth
                margin="normal"
                placeholder="5020"
              />
            </Grid>
          </Grid>
          <TextField
            label="Stadt"
            value={city}
            onChange={(e) => setCity(e.target.value)}
            required
            fullWidth
            margin="normal"
            placeholder="Salzburg"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetForm} variant="secondary">
          Abbrechen
        </Button>
        <Button
          disabled={isSaveDisabled}
          variant="primary"
          onClick={saveToFirebase}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default KarteAdd
