import React, { useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material'
import { ref, push, set } from 'firebase/database'
import { database } from '../../../firebase'

const LesestoffAdd = ({ open, handleClose }) => {
  const [header, setHeader] = useState('')
  const [body, setBody] = useState('')
  const [category, setCategory] = useState('')
  const [img, setImg] = useState('')
  const [imgError, setImgError] = useState(false)

  const saveToFirebase = async () => {
    try {
      const data = {
        header,
        body,
        img,
      }

      const newArticleRef = push(ref(database, `articles/${category}`))
      await set(newArticleRef, data)
      resetForm()
    } catch (error) {
      console.error('Fehler beim Speichern der Daten: ', error)
    }
  }

  const handleImgChange = (event) => {
    const value = event.target.value
    let isValid = true

    try {
      new URL(value)
    } catch (_) {
      isValid = false
    }

    setImgError(!isValid)
    setImg(value)
  }

  const resetForm = () => {
    setHeader('')
    setBody('')
    setCategory('')
    setImg('')
    setImgError(false)
    handleClose()
  }

  const isSaveDisabled = !header || !body || !category || !img || imgError

  return (
    <Dialog open={open} onClose={resetForm} maxWidth="sm" fullWidth>
      <DialogTitle style={{ fontFamily: 'Inter Bold', color: '#f59c46' }}>
        Lesestoff Hinzufügen
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            label="Titel"
            value={header}
            onChange={(e) => setHeader(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Text"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required
            fullWidth
            multiline
            rows={4}
            margin="normal"
          />
          <FormControl fullWidth required margin="normal">
            <InputLabel id="category-label">Kategorie</InputLabel>
            <Select
              labelId="category-label"
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              label="Kategorie"
            >
              <MenuItem value="meineFragen">Meine Fragen</MenuItem>
              <MenuItem value="meineGefühle">Meine Gefühle</MenuItem>
              <MenuItem value="überAlkohol">Über Alkohol</MenuItem>
              <MenuItem value="erfahrungsbericht">Erfahrungsbericht</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label="Bild URL"
            value={img}
            required
            fullWidth
            margin="normal"
            onChange={handleImgChange}
            error={imgError}
            helperText={imgError ? 'Keine gültige URL' : ''}
            placeholder="https://link-to-img.com"
            type="url"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetForm} variant="secondary">
          Abbrechen
        </Button>
        <Button
          disabled={isSaveDisabled}
          variant="primary"
          onClick={saveToFirebase}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LesestoffAdd
