import React, { useState } from 'react'
import { ref, update } from 'firebase/database'
import { Switch, Typography } from '@mui/material'
import { database } from '../firebase'
import { useUser } from '../UserContext'

const HomeScreen = () => {
  const [error, setError] = useState('')
  const { userInfo, setUserInfo } = useUser()

  const handleOnlineStatus = () => {
    const newOnlineStatus = !userInfo.online
    const userRef = ref(database, `admins/${userInfo.uid}`)
    update(userRef, { online: newOnlineStatus })
      .then(() => {
        setUserInfo((prevUserInfo) => ({
          ...prevUserInfo,
          online: newOnlineStatus,
        }))
      })
      .catch((error) => {
        setError('Fehler beim Aktualisieren des Online-Status:', error)
      })
  }

  return (
    <div className="screen">
      <div className="screengeneral">
        <div className="screenheadline">
          <h2>Willkommen, {userInfo.name}!</h2>
          {/* Weitere Inhalte und Darstellungen */}
          <div className="rolehome">
            <p className="datenheadline">Deine aktuellen Anmeldedaten:</p>
            {error && <Typography color="error">{error}</Typography>}
            <p>Name: {userInfo.name}</p>
            <p>E-Mail: {userInfo.email}</p>
            <p>Rolle: {userInfo.role}</p>
            <p>Benutzer-ID: {userInfo.uid}</p>
            <Typography component="div">
              Online Status:{' '}
              <Switch
                checked={!!userInfo.online}
                onChange={handleOnlineStatus}
              />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomeScreen
