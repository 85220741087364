import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from '@mui/material'
import { ref, set, get } from 'firebase/database'
import { database } from '../../../firebase'

const LesestoffEdit = ({ open, handleClose, currentItem }) => {
  const [header, setHeader] = useState('')
  const [body, setBody] = useState('')
  const [img, setImg] = useState('')
  const [imgError, setImgError] = useState(false)

  const handleImgChange = (event) => {
    const value = event.target.value
    let isValid = true

    try {
      new URL(value)
    } catch (_) {
      isValid = false
    }

    setImgError(!isValid)
    setImg(value)
  }

  useEffect(() => {
    const fetchData = async () => {
      if (currentItem.id && currentItem.kategorie && open) {
        const itemRef = ref(
          database,
          `articles/${currentItem.kategorie}/${currentItem.id}`
        )
        try {
          const snapshot = await get(itemRef)
          if (snapshot.exists()) {
            const data = snapshot.val()
            setHeader(data.header)
            setBody(data.body)
            setImg(data.img)
          } else {
            console.log('No data available')
          }
        } catch (error) {
          console.error('Failed to fetch data: ', error)
        }
      }
    }

    fetchData()
  }, [currentItem, open])

  const saveToFirebase = async () => {
    if (!currentItem) {
      console.error('No item selected for update')
      return
    }
    const itemRef = ref(
      database,
      `articles/${currentItem.kategorie}/${currentItem.id}`
    )

    const data = {
      header,
      body,
      img,
    }

    try {
      await set(itemRef, data)
      console.log('Update successful')
      resetForm()
    } catch (error) {
      console.error('Error saving data:', error)
    }
  }

  const resetForm = () => {
    setHeader('')
    setBody('')
    setImg('')
    setImgError(false)
    handleClose()
  }

  const isSaveDisabled = !header || !body || !img || imgError

  return (
    <Dialog open={open} onClose={resetForm} maxWidth="sm" fullWidth>
      <DialogTitle style={{ fontFamily: 'Inter Bold', color: '#f59c46' }}>
        Lesestoff Bearbeiten
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            label="Name"
            value={header}
            onChange={(e) => setHeader(e.target.value)}
            required
            fullWidth
            style={{ marginBottom: '20px', marginTop: '20px' }}
          />
          <TextField
            label="Text"
            value={body}
            onChange={(e) => setBody(e.target.value)}
            required
            fullWidth
            multiline
            rows={4}
            style={{ marginBottom: '20px', marginTop: '20px' }}
          />
          <TextField
            label="Bild URL"
            value={img}
            required
            fullWidth
            margin="normal"
            onChange={handleImgChange}
            error={imgError}
            helperText={imgError ? 'Keine gültige URL' : ''}
            placeholder="https://link-to-img.com"
            type="url"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetForm} variant="secondary">
          Abbrechen
        </Button>
        <Button
          disabled={isSaveDisabled}
          variant="primary"
          onClick={saveToFirebase}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default LesestoffEdit
