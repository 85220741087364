import React, { useState } from 'react'
import { signInWithEmailAndPassword } from 'firebase/auth'
import { TextField, Button, Container, Typography, Box } from '@mui/material'
import { ref, get } from 'firebase/database'
import { useNavigate } from 'react-router-dom'
import { auth, database } from '../firebase'
import logo from '../assets/vmt-logo.svg'

const LoginScreen = () => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault()
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      )
      const uid = userCredential.user.uid
      const adminsRef = ref(database, 'admins/' + uid)
      const snapshot = await get(adminsRef)

      if (snapshot.exists()) {
        navigate('/')
      } else {
        setError('Zugriff verweigert.')
      }
    } catch (error) {
      setError('Login fehlgeschlagen, prüfe E-Mail und Passwort!')
    }
  }

  return (
    <Container component="main" maxWidth="xs" className="login-form">
      <Box
        sx={{
          marginTop: '15vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={logo} alt="Logo" className="logo-login" />
        {error && <Typography color="error">{error}</Typography>}
        <Box component="form" onSubmit={handleLogin} noValidate sx={{ mt: 1 }}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="E-Mail Adresse"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Passwort"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 2,
            }}
          >
            <Button type="submit" variant="primary">
              Anmelden
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default LoginScreen
