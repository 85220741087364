import React, { createContext, useState, useEffect, useContext } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { ref, get } from 'firebase/database'
import { auth, database } from './firebase'

const UserContext = createContext()

export const UserProvider = ({ children }) => {
  const [userInfo, setUserInfo] = useState({
    name: 'Lädt...',
    email: 'Lädt...',
    role: 'Lädt...',
    uid: 'Lädt...',
    online: false,
  })
  const [error, setError] = useState('')

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        const uid = currentUser.uid
        const userRef = ref(database, 'admins/' + uid)

        get(userRef)
          .then((snapshot) => {
            if (snapshot.exists()) {
              const userData = snapshot.val()
              setUserInfo({
                name: userData.username || 'Kein Benutzername',
                email: currentUser.email,
                role: userData.role || 'Keine Rolle',
                uid: uid,
                online: userData.online || false,
              })
            } else {
              setError('Keine Nutzdaten gefunden')
              setUserInfo({ name: '', online: false })
            }
          })
          .catch((error) => {
            setError('Fehler beim Abrufen der Benutzerdaten:', error)
            setUserInfo({ name: '', online: false })
          })
      } else {
        setError('Keine Nutzdaten gefunden')
        setUserInfo({ name: '' })
      }
    })
    return () => unsubscribe()
  }, [])

  return (
    <UserContext.Provider value={{ userInfo, setUserInfo, error }}>
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => useContext(UserContext)
