import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import MaterialTheme from '../components/MaterialTheme'
import { Button } from '@mui/material'
import TableComponent from '../components/TableComponent'
import KarteAdd from '../components/popups/karte/KarteAdd'
import { database } from '../firebase'
import { ref } from 'firebase/database'
import { useObjectVal } from 'react-firebase-hooks/database'
import { remove } from 'firebase/database'
import DeletePopup from '../components/DeletePopup'
import KarteEdit from '../components/popups/karte/KarteEdit'
import { useUser } from '../UserContext'
import { Navigate } from 'react-router-dom'

const KarteScreen = () => {
  const { userInfo } = useUser()
  const [openEdit, setOpenEdit] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const savedLocations = ref(database, 'contactPoints')
  const [contactPoints] = useObjectVal(savedLocations)
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState(null)
  const [currentItem, setCurrentItem] = useState(null)

  const handleDeleteClick = (id) => {
    setDeleteItemId(id)
    setDeletePopupOpen(true)
  }
  const handleCloseDeletePopup = () => {
    setDeletePopupOpen(false)
  }

  const handleConfirmDelete = () => {
    const itemRef = ref(database, `contactPoints/${deleteItemId}`)

    remove(itemRef)
      .then(() => {
        console.log(
          `Element mit der ID ${deleteItemId} wurde erfolgreich gelöscht.`
        )
        setDeletePopupOpen(false)
      })
      .catch((error) => {
        console.error(
          `Fehler beim Löschen des Elements mit der ID ${deleteItemId}:`,
          error
        )
      })
  }

  const handleEditClick = (id) => {
    setCurrentItem(id)
    setOpenEdit(true)
  }

  const handleEditClose = () => {
    setOpenEdit(false)
  }

  const handleAddClick = () => {
    setOpenAdd(true)
  }

  const handleAddClose = () => {
    setOpenAdd(false)
  }

  //DISPLAY DATA
  const columns = ['Name']
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (contactPoints && Object.keys(contactPoints).length === 0) {
      setTableData([])
    } else if (contactPoints) {
      const data = Object.entries(contactPoints).map(([key, point]) => ({
        id: key,
        name: point.title,
      }))
      setTableData(data)
    }
  }, [contactPoints])

  //CHECK ROLE
  if (userInfo.role !== 'Admin' && userInfo.role !== 'Moderator') {
    return <Navigate to="/" />
  }

  //RENDER COMPONENT
  return (
    <ThemeProvider theme={MaterialTheme}>
      <div className="screen">
        <div className="screentable">
          <div className="screenheadline">
            <h2>Karte</h2>
            <Button onClick={handleAddClick}>+</Button>
          </div>
          <TableComponent
            data={tableData}
            columns={columns}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
          <KarteEdit
            open={openEdit}
            handleClose={handleEditClose}
            currentItem={currentItem}
          />
          <KarteAdd open={openAdd} handleClose={handleAddClose} />
        </div>
      </div>
      <DeletePopup
        open={deletePopupOpen}
        handleClose={handleCloseDeletePopup}
        handleDelete={handleConfirmDelete}
      />
    </ThemeProvider>
  )
}

export default KarteScreen
