import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'

const DeletePopup = ({ open, handleClose, handleDelete }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ fontFamily: 'Inter Bold', color: '#f59c46' }}>
        Wirklich Löschen?
      </DialogTitle>
      <DialogContent>Wollen Sie diesen Eintrag wirklich löschen?</DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="primary">
          Abbrechen
        </Button>
        <Button onClick={handleDelete} variant="secondary">
          Löschen
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default DeletePopup
