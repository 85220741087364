import React, { useState, useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { onAuthStateChanged } from 'firebase/auth'
import { ref, get } from 'firebase/database'
import { auth, database } from './firebase'
import { UserProvider } from './UserContext'

import SideBar from './components/SideBar'
import './sass/styles.scss'

// SCREENS
import HomeScreen from './screens/HomeScreen'
import ChatScreen from './screens/ChatScreen'
import LesestoffScreen from './screens/LesestoffScreen'
import KarteScreen from './screens/KarteScreen'
import BetreuerScreen from './screens/BetreuerScreen'
import LoginScreen from './screens/LoginScreen'
import MaterialTheme from './components/MaterialTheme'
import { ThemeProvider } from '@mui/material/styles'

const App = () => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(null)
  useEffect(() => {
    document.title = 'VMT Portal'
  }, [])

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const adminsRef = ref(database, 'admins/' + user.uid)
        const snapshot = await get(adminsRef)

        if (snapshot.exists()) {
          setIsUserLoggedIn(true)
        } else {
          setIsUserLoggedIn(false)
        }
      } else {
        setIsUserLoggedIn(false)
      }
    })

    return () => unsubscribe()
  }, [])

  if (isUserLoggedIn === null) {
    return (
      <div className="loadingcontainer">
        <div className="lodaingmessage">Loading...</div>
      </div>
    )
  }

  return (
    <UserProvider>
      <Router>
        <ThemeProvider theme={MaterialTheme}>
          {isUserLoggedIn ? (
            <div className="appmain">
              <SideBar />
              <Routes>
                <Route path="/" element={<HomeScreen />} />
                <Route path="/chat" element={<ChatScreen />} />
                <Route path="/lesestoff" element={<LesestoffScreen />} />
                <Route path="/karte" element={<KarteScreen />} />
                <Route path="/betreuer" element={<BetreuerScreen />} />
              </Routes>
            </div>
          ) : (
            <LoginScreen />
          )}
        </ThemeProvider>
      </Router>
    </UserProvider>
  )
}

export default App
