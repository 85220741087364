import React from 'react'
import { Link } from 'react-router-dom'
import { signOut } from 'firebase/auth'
import { ref, update } from 'firebase/database'
import { auth, database } from '../firebase'
import { useUser } from '.././UserContext'

//ICONS
import homeIcon from '../assets/icons/home-icon.svg'
import chatIcon from '../assets/icons/chat-icon.svg'
import karteIcon from '../assets/icons/location-icon.svg'
import einstellungenIcon from '../assets/icons/settings-icon.svg'
import profilIcon from '../assets/icons/profile-icon.svg'
import lesestoffIcon from '../assets/icons/book-icon.svg'

//ASSETS
import logo from '../assets/vmt-logo.svg'

const SideBar = () => {
  const { userInfo } = useUser()
  const handleLogout = (userInfo) => {
    const userRef = ref(database, `admins/${userInfo.uid}`)
    update(userRef, { online: false })
      .then(() => {
        return signOut(auth)
      })
      .then(() => {
        console.log('Benutzer erfolgreich abgemeldet')
      })
      .catch((error) => {
        console.error('Fehler beim Abmelden:', error)
      })
  }

  if (userInfo.role === 'Admin') {
    return (
      <div className="sidebar">
        <nav className="mainsidebar">
          <ul>
            <Link to="/">
              <img src={logo} alt="Logo" className="logo" />
            </Link>
            <li>
              <Link to="/">
                <img src={homeIcon} alt="Home" className="icon" />
                Home
              </Link>
            </li>
            <li>
              <Link to="/chat">
                <img src={chatIcon} alt="Chat" className="icon" /> Chat
              </Link>
            </li>
            <li>
              <Link to="/lesestoff">
                <img src={lesestoffIcon} alt="Lesestoff" className="icon" />{' '}
                Lesestoff
              </Link>
            </li>
            <li>
              <Link to="/karte">
                <img src={karteIcon} alt="Karte" className="icon" /> Karte
              </Link>
            </li>
            <li>
              <Link to="/betreuer">
                <img src={profilIcon} alt="Betreuer" className="icon" />{' '}
                Betreuer
              </Link>
            </li>
          </ul>
        </nav>
        <div className="bottomsidebar">
          <button onClick={() => handleLogout(userInfo)}>Abmelden</button>
        </div>
      </div>
    )
  } else if (userInfo.role === 'Moderator') {
    return (
      <div className="sidebar">
        <nav className="mainsidebar">
          <ul>
            <Link to="/">
              <img src={logo} alt="Logo" className="logo" />
            </Link>
            <li>
              <Link to="/">
                <img src={homeIcon} alt="Home" className="icon" />
                Home
              </Link>
            </li>
            <li>
              <Link to="/chat">
                <img src={chatIcon} alt="Chat" className="icon" /> Chat
              </Link>
            </li>
            <li>
              <Link to="/lesestoff">
                <img src={lesestoffIcon} alt="Lesestoff" className="icon" />{' '}
                Lesestoff
              </Link>
            </li>
            <li>
              <Link to="/karte">
                <img src={karteIcon} alt="Karte" className="icon" /> Karte
              </Link>
            </li>
          </ul>
        </nav>
        <div className="bottomsidebar">
          <button onClick={() => handleLogout(userInfo)}>Abmelden</button>
        </div>
      </div>
    )
  } else {
    return (
      <div className="sidebar">
        <nav className="mainsidebar">
          <ul>
            <Link to="/">
              <img src={logo} alt="Logo" className="logo" />
            </Link>
            <li>
              <Link to="/">
                <img src={homeIcon} alt="Home" className="icon" />
                Home
              </Link>
            </li>
            <li>
              <Link to="/chat">
                <img src={chatIcon} alt="Chat" className="icon" /> Chat
              </Link>
            </li>
          </ul>
        </nav>
        <div className="bottomsidebar">
          <button onClick={() => handleLogout(userInfo)}>Abmelden</button>
        </div>
      </div>
    )
  }
}

export default SideBar
