import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import MaterialTheme from '../components/MaterialTheme'
import { Button } from '@mui/material'
import BetreuerTableComponent from '../components/popups/betreuer/BetreuerTableComponent'
import { database } from '../firebase'
import { ref } from 'firebase/database'
import { useObjectVal } from 'react-firebase-hooks/database'
import { remove } from 'firebase/database'
import DeletePopup from '../components/DeletePopup'
import AdminAddPopup from '../components/popups/betreuer/AdminAddPopup'
import { getFunctions, httpsCallable } from 'firebase/functions'
import { useUser } from '../UserContext'
import { Navigate } from 'react-router-dom'

const BetreuerScreen = () => {
  const { userInfo } = useUser()
  const [openAdd, setOpenAdd] = useState(false)
  const adminsRef = ref(database, 'admins')
  const [admins] = useObjectVal(adminsRef)
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState(null)

  const handleDeleteClick = (id) => {
    setDeleteItemId(id)
    setDeletePopupOpen(true)
  }
  const handleCloseDeletePopup = () => {
    setDeletePopupOpen(false)
  }

  const handleConfirmDelete = async () => {
    const itemRef = ref(database, `admins/${deleteItemId}`)
    const functions = getFunctions()
    const deleteUser = httpsCallable(functions, 'deleteUser')

    try {
      await remove(itemRef)
      console.log(`Benutzer erfolgreich aus Realtime gelöscht.`)

      await deleteUser({ uid: deleteItemId })
      console.log(`Benutzer erfolgreich aus Auth gelöscht.`)

      setDeletePopupOpen(false)
    } catch (error) {
      console.error(`Fehler beim Löschen:`, error)
    }
  }

  const handleAddClick = () => {
    setOpenAdd(true)
  }

  const handleAddClose = () => {
    setOpenAdd(false)
  }

  //DISPLAY DATA
  const columns = ['ID', 'Name', 'Rolle', 'Online']
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (admins && Object.keys(admins).length === 0) {
      setTableData([])
    } else if (admins) {
      const data = Object.entries(admins).map(([key, admin]) => ({
        id: key,
        name: admin.username,
        rolle: admin.role,
        online: admin.online ? 'Ja' : 'Nein',
      }))
      setTableData(data)
    }
  }, [admins])

  //CHECK ROLE
  if (userInfo.role !== 'Admin') {
    return <Navigate to="/" />
  }

  //RENDER COMPONENT
  return (
    <ThemeProvider theme={MaterialTheme}>
      <div className="screen">
        <div className="screentable">
          <div className="screenheadline">
            <h2>Betreuer</h2>
            <Button onClick={handleAddClick}>+</Button>
          </div>
          <BetreuerTableComponent
            data={tableData}
            columns={columns}
            handleDeleteClick={handleDeleteClick}
          />
          <AdminAddPopup open={openAdd} handleClose={handleAddClose} />
        </div>
      </div>
      <DeletePopup
        open={deletePopupOpen}
        handleClose={handleCloseDeletePopup}
        handleDelete={handleConfirmDelete}
      />
    </ThemeProvider>
  )
}

export default BetreuerScreen
