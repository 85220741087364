import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Grid,
} from '@mui/material'
import { ref, set, get } from 'firebase/database'
import { database } from '../../../firebase'

const KarteEdit = ({ open, handleClose, currentItem }) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [website, setWebsite] = useState('')
  const [phone, setPhone] = useState('')
  const [adress, setAdress] = useState('')
  const [websiteError, setWebsiteError] = useState(false)
  const [phoneError, setPhoneError] = useState(false)

  const geocodeApiKey = process.env.REACT_APP_GEOCODE_API_KEY

  useEffect(() => {
    const fetchData = async () => {
      if (currentItem && open) {
        const itemRef = ref(database, `contactPoints/${currentItem}`)
        try {
          const snapshot = await get(itemRef)
          if (snapshot.exists()) {
            const data = snapshot.val()
            setName(data.title)
            setDescription(data.description)
            setWebsite(data.url)
            setPhone(data.tel)
            setAdress(data.address)
          } else {
            console.log('No data available')
          }
        } catch (error) {
          console.error('Failed to fetch data: ', error)
        }
      }
    }

    fetchData()
  }, [currentItem, open])

  const saveToFirebase = async () => {
    if (!currentItem) {
      console.error('No item selected for update')
      return
    }

    const newStreet = encodeURIComponent(adress)
    await fetch(
      `https://geocode.maps.co/search?q=${newStreet}&api_key=${geocodeApiKey}`
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok ' + response.statusText)
        }
        return response.json()
      })
      .then((json) => {
        const responseLat = parseFloat(json[0].lat)
        const responseLon = parseFloat(json[0].lon)
        if (!isNaN(responseLat) && !isNaN(responseLon)) {
          if (responseLat != 0 && responseLon != 0) {
            const data = {
              address: adress,
              description: description,
              tel: phone,
              title: name,
              url: website,
              coordinates: {
                latitude: responseLat,
                longitude: responseLon,
              },
            }
            const itemRef = ref(database, `contactPoints/${currentItem}`)
            try {
              set(itemRef, data)
              resetForm()
            } catch (error) {
              console.error('Error saving data:', error)
            }
          } else {
            console.log(`Error: ${responseLat} or ${responseLon} == 0`)
          }
        } else {
          console.error('Latitude or Longitude is not a number.')
        }
      })
      .catch((error) => {
        console.log('ungültige Adresse: ', error)
        alert('Ungültige Adresse')
      })
  }

  const handleWebsiteChange = (event) => {
    const value = event.target.value
    const isValid =
      /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/.test(
        value
      )
    setWebsite(value)
    setWebsiteError(!isValid)
  }

  const handlePhoneChange = (event) => {
    const value = event.target.value
    const isValid = /^(\+43|\+49)[0-9]{8,15}$/.test(value)
    setPhone(value)
    setPhoneError(!isValid)
  }

  const resetForm = () => {
    setName('')
    setDescription('')
    setWebsite('')
    setPhone('')
    setAdress('')
    setWebsiteError(false)
    setPhoneError(false)
    handleClose()
  }

  const isSaveDisabled =
    !name || !description || !website || !phone || websiteError || phoneError

  return (
    <Dialog open={open} onClose={resetForm} maxWidth="sm" fullWidth>
      <DialogTitle style={{ fontFamily: 'Inter Bold', color: '#f59c46' }}>
        Standort Bearbeiten
      </DialogTitle>
      <DialogContent>
        <form>
          <TextField
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <TextField
            label="Beschreibung"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                label="Website"
                value={website}
                onChange={handleWebsiteChange}
                error={websiteError}
                helperText={websiteError ? 'Keine gültige URL' : ''}
                required
                fullWidth
                margin="normal"
                placeholder="https://example.com"
                type="url"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Telefon"
                value={phone}
                onChange={handlePhoneChange}
                error={phoneError}
                helperText={phoneError ? 'Keine gültige Telefonnummer' : ''}
                required
                fullWidth
                margin="normal"
                placeholder="+4369912345678"
              />
            </Grid>
          </Grid>
          <TextField
            label="Adresse"
            value={adress}
            onChange={(e) => setAdress(e.target.value)}
            required
            fullWidth
            margin="normal"
          />
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={resetForm} variant="secondary">
          Abbrechen
        </Button>
        <Button
          disabled={isSaveDisabled}
          variant="primary"
          onClick={saveToFirebase}
        >
          Speichern
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default KarteEdit
