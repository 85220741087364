import { createTheme } from '@mui/material/styles'

const MaterialTheme = createTheme({
  palette: {
    primary: {
      main: '#ff9100',
    },
    secondary: {
      main: '#f44336',
    },
    error: {
      main: '#e95031',
    },
    success: {
      main: '#a3ec81',
    },
  },
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'primary' },
          style: {
            color: '#f59c46',
            border: '2px solid #f59c46',
            borderRadius: '20px',
            width: '150px',
            '&:hover': {
              backgroundColor: '#f59c46',
              color: 'white',
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26)',
              border: '2px solid #e0e0e0',
            },
          },
        },
        {
          props: { variant: 'block' },
          style: {
            height: '40px',
            color: '#f59c46',
            '&:hover': {
              backgroundColor: '#f8c4ba',
            },
          },
        },
        {
          props: { variant: 'secondary' },
          style: {
            color: '#e95031',
            border: '2px solid #e95031',
            borderRadius: '20px',
            width: '150px',
            '&:hover': {
              backgroundColor: '#e95031',
              color: 'white',
            },
            '&.Mui-disabled': {
              color: 'rgba(0, 0, 0, 0.26)',
              border: '2px solid #e0e0e0',
            },
          },
        },
      ],
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'rgba(0, 0, 0, 0.6)',
          fontFamily: 'Inter Regular',
          fontSize: 14,
          '&.Mui-focused': {
            color: '#ff9100',
          },
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          fontFamily: 'Inter Regular',
          color: 'rgba(0, 0, 0, 0.6)',
          fontSize: 14,
        },
      },
    },
  },
})

export default MaterialTheme
