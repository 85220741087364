import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material'

const BetreuerTableComponent = ({ data, columns, handleDeleteClick }) => {
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              {columns.map((column) => (
                <TableCell key={column}>{item[column.toLowerCase()]}</TableCell>
              ))}
              <TableCell>
                <Button
                  onClick={() => handleDeleteClick(item.id)}
                  variant="secondary"
                >
                  Löschen
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default BetreuerTableComponent
