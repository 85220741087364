import React, { useState, useEffect } from 'react'
import { ThemeProvider } from '@mui/material/styles'
import MaterialTheme from '../components/MaterialTheme'
import { Button } from '@mui/material'
import LesestoffTableComponent from '../components/popups/lesestoff/LesestoffTableComponent'
import { database } from '../firebase'
import { ref } from 'firebase/database'
import { remove } from 'firebase/database'
import { useObjectVal } from 'react-firebase-hooks/database'
import LesestoffEdit from '../components/popups/lesestoff/LesestoffEdit'
import LesestoffAdd from '../components/popups/lesestoff/LesestoffAdd'
import DeletePopup from '../components/DeletePopup'
import { useUser } from '../UserContext'
import { Navigate } from 'react-router-dom'

const LesestoffScreen = () => {
  const { userInfo } = useUser()
  const [openEdit, setOpenEdit] = useState(false)
  const [openAdd, setOpenAdd] = useState(false)
  const savedArticles = ref(database, 'articles')
  const [articles, loading, error] = useObjectVal(savedArticles)
  const [currentItem, setCurrentItem] = useState({ id: null, kategorie: null })
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)
  const [deleteItem, setDeleteItem] = useState({ id: null, kategorie: null })

  const handleDeleteClick = (id, kategorie) => {
    setDeleteItem({ id, kategorie })
    setDeletePopupOpen(true)
  }

  const handleCloseDeletePopup = () => {
    setDeletePopupOpen(false)
  }

  const handleConfirmDelete = () => {
    const itemRef = ref(
      database,
      `articles/${deleteItem.kategorie}/${deleteItem.id}`
    )

    remove(itemRef)
      .then(() => {
        console.log(
          `Element mit der ID ${deleteItem.id} wurde erfolgreich gelöscht.`
        )
        setDeletePopupOpen(false)
      })
      .catch((error) => {
        console.error(
          `Fehler beim Löschen des Elements mit der ID ${deleteItem.id}:`,
          error
        )
      })
  }

  const handleEditClick = (id, kategorie) => {
    setCurrentItem({ id, kategorie })
    setOpenEdit(true)
  }

  const handleEditClose = () => {
    setOpenEdit(false)
  }

  const handleAddClick = () => {
    setOpenAdd(true)
  }

  const handleAddClose = () => {
    setOpenAdd(false)
  }

  //DISPLAY DATA
  const columns = ['ID', 'Titel', 'Kategorie']
  const [tableData, setTableData] = useState([])

  useEffect(() => {
    if (articles && Object.keys(articles).length === 0) {
      setTableData([])
    } else if (articles) {
      const data = Object.entries(articles).flatMap(
        ([category, categoryArticles]) =>
          Object.entries(categoryArticles).map(([key, article]) => ({
            id: key,
            titel: article.header || '',
            kategorie: category,
          }))
      )
      setTableData(data)
    }
  }, [articles])

  //CHECK ROLE
  if (userInfo.role !== 'Admin' && userInfo.role !== 'Moderator') {
    return <Navigate to="/" />
  }

  //RENDER COMPONENT
  return (
    <ThemeProvider theme={MaterialTheme}>
      <div className="screen">
        <div className="screentable">
          <div className="screenheadline">
            <h2>Lesestoff</h2>
            <Button onClick={handleAddClick}>+</Button>
          </div>
          <LesestoffTableComponent
            data={tableData}
            columns={columns}
            handleEditClick={handleEditClick}
            handleDeleteClick={handleDeleteClick}
          />
          <LesestoffEdit
            open={openEdit}
            handleClose={handleEditClose}
            currentItem={currentItem}
          />
          <LesestoffAdd open={openAdd} handleClose={handleAddClose} />
        </div>
      </div>
      <DeletePopup
        open={deletePopupOpen}
        handleClose={handleCloseDeletePopup}
        handleDelete={handleConfirmDelete}
      />
    </ThemeProvider>
  )
}

export default LesestoffScreen
