import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from '@mui/material'

const LesestoffTableComponent = ({
  data,
  columns,
  handleEditClick,
  handleDeleteClick,
}) => {
  return (
    <TableContainer
      component={Paper}
      style={{ maxHeight: '70vh', overflow: 'auto' }}
    >
      <Table>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={column}>{column}</TableCell>
            ))}
            <TableCell>Aktionen</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((item) => (
            <TableRow key={item.id}>
              {columns.map((column) => (
                <TableCell key={column}>{item[column.toLowerCase()]}</TableCell>
              ))}
              <TableCell>
                <Button
                  onClick={() =>
                    handleEditClick(item.id, item.kategorie, item.titel)
                  }
                  variant="primary"
                  style={{ marginRight: '10px' }}
                >
                  Bearbeiten
                </Button>
                <Button
                  onClick={() => handleDeleteClick(item.id, item.kategorie)}
                  variant="secondary"
                >
                  Löschen
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default LesestoffTableComponent
