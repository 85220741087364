import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@mui/material'

const BlockPopup = ({ open, handleClose, handleBlock }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle style={{ fontFamily: 'Inter Bold', color: '#f59c46' }}>
        Wirklich Blockieren?
      </DialogTitle>
      <DialogContent>
        Wollen Sie diesen Benutzer wirklich dauerhaft blockieren? Diese Aktion
        ist unwiderruflich und verweigert dem Benutzer dauerhaft den Zugriff auf
        die App!
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="primary">
          Abbrechen
        </Button>
        <Button onClick={handleBlock} variant="secondary">
          Blockieren
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default BlockPopup
